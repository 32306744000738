.input-group-icon{
  position: relative;
  .input-box{
    padding-left: 2.8rem;
    vertical-align: middle;
  }
  .input-box-icon{
    position: absolute;
    top:50%;
    left: 2rem;
    color:$gray-300;
    transform: translateY(-50%);
  }
}



/*-----------------------------------------------
|   textarea box
-----------------------------------------------*/
.input-group-text-icon{
  position: relative;
  .input-box{
    padding-left: 2.8rem;
    vertical-align: middle;
  }
  .input-box-text-icon{
    position: absolute;
    color: $gray-500;
    top:15%;
    left: 2rem;
    color:$gray-300;
    transform: translateY(-50%);
  }
}



.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($font-size-lg);
}  