/*-----------------------------------------------
|   LaslesVPN glow buttons
-----------------------------------------------*/
.btn-glow {
  position: relative;
  &::before{
    content: '';
    height: 80%;
    width: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    display: inline-block;
    background-color: inherit;
    opacity: 0.55;
    filter: blur(26px);
    transform: translateY(45%);
    z-index: -1;
    border-radius: 10px;
  }
}


// // Gradient Button

//  .btn-gradient {
//   font-size: 16px;
//   color: #FFF;
//   font-weight: bold;
// }

.btn-gradient:hover{
  box-shadow: 0 10px 20px $pink, 0 6px 6px $primary;
}

.btn-collab {
  color: #FFF;
  font-weight: bold;
  background-image: linear-gradient(50deg, rgba(254,154,82,1), rgba(255,87,72,1));
}

.btn-collab-outline { 
  position: relative;

&::before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:inherit;
    background-size:auto;
    background: linear-gradient(50deg, $danger, $warning);
    mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="1" y="1" width="100%" height="100%" style="height:calc(100% - 2px);width:calc(100% - 2px)" rx="6" ry="6" stroke-width="1" fill="transparent" stroke="white"/></svg>') 0 / 100% 100%;
}
}
